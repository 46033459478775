// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_rn g_hp g_dw";
export var heroHeaderCenter = "s_hq g_hq g_dw g_fz";
export var heroHeaderRight = "s_hr g_hr g_dw g_fB";
export var heroParagraphLeft = "s_rp g_hl g_dz";
export var heroParagraphCenter = "s_hm g_hm g_dz g_fz";
export var heroParagraphRight = "s_hn g_hn g_dz g_fB";
export var heroBtnWrapperLeft = "s_rq g_p g_f0 g_bD g_cD g_cK";
export var heroBtnWrapperCenter = "s_rr g_f1 g_f0 g_bD g_cD g_cH";
export var heroBtnWrapperRight = "s_rs g_f2 g_f0 g_bD g_cD g_cL";
export var overlayBtnWrapper = "s_rt g_hk g_b6 g_cf g_cg g_ch g_cr g_dg";
export var design4 = "s_rv g_hj g_b6 g_cf g_cg g_cr";
export var heroExceptionSmall = "s_rw v_rw";
export var heroExceptionNormal = "s_rx v_rx";
export var heroExceptionLarge = "s_ry v_ry";
export var Title1Small = "s_rz v_rz v_rK v_rL";
export var Title1Normal = "s_rB v_rB v_rK v_rM";
export var Title1Large = "s_rC v_rC v_rK v_rN";
export var BodySmall = "s_rD v_rD v_rK v_r2";
export var BodyNormal = "s_rF v_rF v_rK v_r3";
export var BodyLarge = "s_rG v_rG v_rK v_r4";