// extracted by mini-css-extract-plugin
export var galleryMasonryImageWrapper = "r_ky g_ky g_dy";
export var galleryMasonryImage = "r_bf g_bf g_bD g_cV g_fF";
export var alignLeft = "r_qr g_gm g_cK g_fy";
export var alignCenter = "r_cS g_gn g_cH g_fz";
export var alignRight = "r_qs g_gp g_cL g_fB";
export var galleryContainer = "r_rf g_fW";
export var galleryContainerFull = "r_rg g_fT";
export var galleryRowWrapper = "r_rh g_dg";
export var galleryGuttersImage = "r_kB g_kB g_bR g_dy";
export var galleryNoGuttersImage = "r_kz g_kz g_bR g_dH";
export var galleryTextGutters = "r_kw g_kw g_dz";
export var galleryTextNoGutters = "r_kx g_kx g_dz";
export var galleryTextMasonry = "r_rj g_kw g_dz";
export var galleryImageWrapper = "r_rk g_gd g_b5";
export var descText = "r_rl g_kC g_b6 g_ch g_cf g_cg g_bv";
export var guttersDesc = "r_rm";