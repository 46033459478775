// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "y_gN g_gN g_cD g_cL g_cM";
export var navbarDividedRight = "y_gP g_gP g_cD g_cM";
export var menuLeft = "y_sT g_gL g_cD g_fz g_cS g_cM";
export var menuRight = "y_sV g_gL g_cD g_fz g_cS g_cM";
export var menuCenter = "y_sW g_gM g_gL g_cD g_fz g_cS g_bD g_cH g_cM";
export var menuDivided = "y_n5 g_gM g_gL g_cD g_fz g_cS g_bD g_cH";
export var navbarItem = "y_n6 g_cB";
export var navbarLogoItemWrapper = "y_gT g_gT g_cG g_cS";
export var burgerToggleVisibleOpen = "y_sX g_g7 g_cC g_b5 g_cw";
export var burgerToggleVisible = "y_sY g_g7 g_cC g_b5 g_cw";
export var burgerToggle = "y_sZ g_g7 g_cC g_b5 g_cw g_b5";
export var burgerToggleOpen = "y_s0 g_g7 g_cC g_b5 g_cw g_b9";
export var burgerInput = "y_s1 g_gY g_bD g_bP g_cC g_dg g_fp g_cl g_b6 g_cg g_cf g_cb g_ch";
export var burgerLine = "y_s2 g_gX";
export var burgerMenuLeft = "y_s3 g_g2 g_g1 g_gZ g_g0 g_b6 g_dg g_fq g_cw g_fz";
export var burgerMenuRight = "y_s4 g_g3 g_g1 g_gZ g_g0 g_b6 g_dg g_fq g_cw g_fz";
export var burgerMenuCenter = "y_s5 g_g4 g_g1 g_gZ g_g0 g_b6 g_dg g_fq g_cw g_fz";
export var burgerMenuDivided = "y_s6 g_g2 g_g1 g_gZ g_g0 g_b6 g_dg g_fq g_cw g_fz";
export var secondary = "y_s7 g_cG g_cS";
export var staticBurger = "y_s8";
export var menu = "y_s9";
export var navbarDividedLogo = "y_tb";
export var nav = "y_tc";