// extracted by mini-css-extract-plugin
export var lbContainerOuter = "z_td";
export var lbContainerInner = "z_tf";
export var movingForwards = "z_tg";
export var movingForwardsOther = "z_th";
export var movingBackwards = "z_tj";
export var movingBackwardsOther = "z_tk";
export var lbImage = "z_tl";
export var lbOtherImage = "z_tm";
export var prevButton = "z_tn";
export var nextButton = "z_tp";
export var closing = "z_tq";
export var appear = "z_tr";