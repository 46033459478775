// extracted by mini-css-extract-plugin
export var alignLeft = "t_qr g_gm g_cK g_fy";
export var alignCenter = "t_cS g_gn g_cH g_fz";
export var alignRight = "t_qs g_gp g_cL g_fB";
export var menuContainer = "t_rH g_fW";
export var menuContainerFull = "t_rJ g_fT";
export var menuMainHeader = "t_km g_km g_bD g_d6";
export var menuComponentWrapper = "t_kq g_kq g_dy";
export var menuComponentWrapperDesign2 = "t_kr g_kr g_dC";
export var menuComponentText = "t_kn g_kn g_dr";
export var menuComponentTextDesign2 = "t_kp g_kp g_dw";
export var menuImageWrapperDesign2 = "t_ks g_ks g_bD g_b5";