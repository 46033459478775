// extracted by mini-css-extract-plugin
export var articleWrapper = "n_pL";
export var articleText = "n_pM g_fy g_dw";
export var header = "n_pN g_b5 g_bD";
export var headerImageWrapper = "n_pP g_cl g_b6 g_cg g_cf g_cb g_ch g_cn g_cV";
export var headerGradient = "n_pQ g_cl g_b6 g_cg g_cf g_cb g_ch";
export var headerGradientOverlay = "n_pR g_cl g_b6 g_cg g_cf g_cb g_ch";
export var headerContentWrapper = "n_pS g_cD g_cS g_cH g_cM g_cp g_b5";
export var headerContentWrapperAlt = "n_pT g_cD g_cS g_cH g_cM g_cp g_b5";
export var contentWrapper = "n_pV g_bD";
export var dateTag = "n_pW g_dj g_dv g_ft g_bD";
export var icon1 = "n_pX g_dS";
export var icon2 = "n_pY g_dS g_dM";
export var tagBtn = "n_pZ g_c4 g_dH g_fs g_fF";
export var headerText = "n_p0 g_bD g_dj g_dw g_cZ";
export var center = "n_p1 g_fz";
export var videoIframeStyle = "n_p2 g_f3 g_bD g_bP g_cC g_c4 g_bY";
export var articleImageWrapper = "n_p3 g_dw g_b5";
export var articleImageWrapperIcon = "n_p4 g_dw g_b5";
export var articleRow = "n_p5 g_cH";
export var quoteWrapper = "n_hH g_cG g_cS g_dw";
export var quoteBar = "n_p6 g_bP";
export var quoteText = "n_p7";
export var authorBox = "n_p8 g_bD";
export var authorRow = "n_p9 g_cH g_cZ g_dC";
export var separator = "n_qb g_bD";
export var line = "n_gh g_bD g_gh g_d1 g_dC";
export var authorImage = "n_l4 g_db g_b5 g_bD g_dx";
export var authorText = "n_qc g_dl g_dx";
export var masonryImageWrapper = "n_qd";
export var bottomSeparator = "n_qf g_bD g_dC";
export var linksWrapper = "n_qg g_dl g_fy";
export var comments = "n_qh g_cH g_dC";
export var sharing = "n_qj g_cD g_cH g_cM";
export var shareText = "n_qk g_bD g_fz";
export var icon = "n_ql";
export var customRow = "n_qm g_cH g_b5";
export var text = "n_pw";
export var SubtitleSmall = "n_qn";
export var SubtitleNormal = "n_qp";
export var SubtitleLarge = "n_qq";