// extracted by mini-css-extract-plugin
export var alignLeft = "B_qr g_gm g_cK g_fy";
export var alignCenter = "B_cS g_gn g_cH g_fz";
export var alignRight = "B_qs g_gp g_cL g_fB";
export var element = "B_ts g_dw g_dk";
export var customImageWrapper = "B_tt g_dw g_dk g_b5";
export var imageWrapper = "B_pG g_dw g_b5";
export var masonryImageWrapper = "B_qd";
export var gallery = "B_tv g_bD g_cD";
export var width100 = "B_bD";
export var map = "B_tw g_bD g_bP g_b5";
export var quoteWrapper = "B_hH g_cG g_cS g_dw g_dk g_fy";
export var quote = "B_tx g_cG g_cS g_fy";
export var quoteBar = "B_p6 g_bP";
export var quoteText = "B_p7";
export var customRow = "B_qm g_cH g_b5";
export var separatorWrapper = "B_ty g_bD g_cD";
export var articleText = "B_pM g_dw";
export var videoIframeStyle = "B_p2 g_f3 g_bD g_bP g_cC g_c4 g_bY";