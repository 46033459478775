// extracted by mini-css-extract-plugin
export var campaignContainer = "m_pn g_fW";
export var campaignContainerFull = "m_pp g_fT";
export var wrapper = "m_pq g_bD";
export var leftWrapper = "m_pr g_cD g_cR";
export var rightWrapper = "m_ps";
export var subtitle = "m_pt";
export var title = "m_pv";
export var text = "m_pw g_dw";
export var inlineWrapper = "m_px g_cG g_cK g_bD";
export var btnsWrapper = "m_py m_px g_cG g_cK g_bD g_cM";
export var priceWrapper = "m_pz m_px g_cG g_cK g_bD g_dl g_cS";
export var priceHeading = "m_pB";
export var disclaimerWrapper = "m_pC g_dH";
export var mediaSlot = "m_pD";
export var listWrapper = "m_pF g_cG g_cS g_bD";
export var btnWrapper = "m_p";
export var imageWrapper = "m_pG g_gd g_b5";
export var SmallSmall = "m_pH v_pH v_rK v_r5";
export var SmallNormal = "m_pJ v_pJ v_rK v_r6";
export var SmallLarge = "m_pK v_pK v_rK v_r3";