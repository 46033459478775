// extracted by mini-css-extract-plugin
export var alignDiscLeft = "q_qJ g_gm g_cK g_fy";
export var alignLeft = "q_qr g_gm g_cK g_fy";
export var alignDiscCenter = "q_qK g_gn g_cH g_fz";
export var alignCenter = "q_cS g_gn g_cH g_fz";
export var alignDiscRight = "q_qL g_gp g_cL g_fB";
export var alignRight = "q_qs g_gp g_cL g_fB";
export var footerContainer = "q_qM g_fW g_cZ";
export var footerContainerFull = "q_qN g_fT g_cZ";
export var footerHeader = "q_kZ g_kZ";
export var footerTextWrapper = "q_qP g_bD";
export var footerDisclaimerWrapper = "q_k4 g_k4 g_dn";
export var badgeWrapper = "q_qQ g_bD g_cD g_cS g_cJ g_cM";
export var footerDisclaimerRight = "q_qR undefined";
export var footerDisclaimerLeft = "q_qS undefined";
export var verticalTop = "q_qT g_cD g_cM g_cR g_cP";
export var firstWide = "q_qV";
export var disclaimer = "q_qW g_cD g_cM";
export var socialDisclaimer = "q_qX";
export var left = "q_qY";
export var wide = "q_qZ g_dC";
export var right = "q_q0 g_cN";
export var line = "q_gh g_gj g_dy";
export var badgeDisclaimer = "q_q1 g_cG g_cS g_cM";
export var badgeContainer = "q_q2 g_cD g_cL g_cS";
export var badge = "q_q3";
export var padding = "q_q4 g_fb";
export var end = "q_q5 g_cL";
export var linkWrapper = "q_q6 g_fF";
export var link = "q_v g_fF";
export var colWrapper = "q_q7 g_dB";
export var consent = "q_f g_f g_cG g_cS";
export var media = "q_q8 g_cB g_fC";
export var itemRight = "q_q9";
export var itemLeft = "q_rb";
export var itemCenter = "q_rc";
export var exceptionWeight = "q_rd v_r9";